(function () {
    angular.module('informaApp')
        .component('chartViewProfile', {
            templateUrl: 'components/chart-view-profile/template.html',
            controller: ChartViewProfileComponent,
            bindings: {
                source: '<',
                chartFilter: '<',
                hiddenPhases: '<',
                onChartFilterChanges: '<',
                getSearchData: '<',
                onEmpty: '<',
                onShow: '<'
            }
        });

    const DEFAULT_TITLE = 'Chart View';

    function ChartViewProfileComponent(BarChartMapper, ExportSummaryChartService, ChartViewMapper) {
        this.isExporting = false;

        this.compareData = (left, right) => {
            return left.data.name === right.data.name;
        };

        this.onFilterChanges = (filter) => {
            if (!isFilterChanged.call(this, filter)) {
                return;
            }

            this.filter = filter;

            updateProfileData.call(this, BarChartMapper);

            emitOnChartFilterChanges.call(this);
        };

        this.$onChanges = (changes) => {
            if (changes.source && this.source) {
                this.chartData = BarChartMapper.mapAll(this.source);

                updateProfileData.call(this, BarChartMapper);
            }
        };

        this.checkboxValueChanged = () => {
            this.chartOptions = {
                showNumbers: this.showNumbers
            };

            updateProfileData.call(this, BarChartMapper);
        };

        this.onProfileEmpty = () => {
            if (this.onEmpty) {
                this.onEmpty();
            }
        };

        this.onProfileShow = (tab) => {
            if (!this.source && tab.data.source) {
                this.source = tab.data.source;
                this.showNumbers = tab.data.showNumbers;

                updateChartFilter.call(this, tab.data.filter);
                this.chartData = BarChartMapper.mapAll(this.source);
            }

            if (this.onShow) {
                this.onShow(tab);
            }
        };

        this.exportChart = () => exportChart.call(this, ExportSummaryChartService, ChartViewMapper);
    }

    function emitOnChartFilterChanges() {
        if (this.onChartFilterChanges) {
            this.onChartFilterChanges(_.merge({}, this.filter));
        }
    }

    function updateChartFilter(filter) {
        this.chartFilter = filter;
    }

    function isFilterChanged(filter) {
        return !this.filter ||
            this.filter.metric !== filter.metric ||
            this.filter.isBoxPlot !== filter.isBoxPlot;
    }

    function exportChart(ExportSummaryChartService, ChartViewMapper) {
        const searchData = this.getSearchData();
        const exportData = ChartViewMapper.mapSelectedItemsForExport(this.source);

        this.isExporting = true;

        return exportByChartType.call(this, searchData, exportData, ExportSummaryChartService)
            .finally(() => this.isExporting = false);
    }

    function exportByChartType(searchData, exportData, ExportSummaryChartService) {
        return this.isBoxPlot
            ? ExportSummaryChartService.exportBoxplotChart(searchData, exportData)
            : ExportSummaryChartService.exportBarChart(searchData, exportData);
    }

    function updateProfileData() {
        this.profileData = {
            data: {
                name: DEFAULT_TITLE,
                source: this.source,
                filter: this.filter,
                showNumbers: this.showNumbers
            },
            replace: true
        };
    }
})();
